import React from "react"
import { Link } from "gatsby"

const Layout = props => {
  const { title, children } = props
  const [toggleNav, setToggleNav] = React.useState(false)
  return (
    <div className={`site-wrapper ${toggleNav ? `site-head-open` : ``}`}>
      <header className="site-head">
        <div className="site-head-container">
          <a
            className="nav-burger"
            href={`#`}
            onClick={() => setToggleNav(!toggleNav)}
          >
            <div
              className="hamburger hamburger--collapse"
              aria-label="Menu"
              role="button"
              aria-controls="navigation"
            >
              <div className="hamburger-box">
                <div className="hamburger-inner" />
              </div>
            </div>
          </a>
          <nav id="swup" className="site-head-left">
            <ul className="nav" role="menu">
              <li className="nav-apps" role="menuitem">
                <Link activeClassName="nav-current" to={`/apps`}>
                  Apps
                </Link>
              </li>
              <li className="nav-development" role="menuitem">
                <Link activeClassName="nav-current" to={`/development`}>
                  Development
                </Link>
              </li>
              <li className="nav-physical" role="menuitem">
                <Link activeClassName="nav-current" to={`/skillzgames`}>
                  Skillz Games
                </Link>
              </li>
            </ul>
          </nav>
          <div className="site-head-center">
            <Link className="site-head-logo" to={`/`}>
              {title}
            </Link>
          </div>
          <div className="site-head-right">
            <div className="social-links">
              <Link activeClassName="nav-current" to={`/`} title="Home">
                Home
              </Link>
              {/* <Link
                partiallyActive
                activeClassName="nav-current"
                to={`/posts`}
                title="Blog"
                >
                Blog
              </Link> */}

              {/* <a
                href={`/rss.xml`}
                title="RSS"
                target="_blank"
                rel="noopener noreferrer"
                >
                RSS
              </a> */}
            </div>
          </div>
        </div>
      </header>
      <main id="site-main" className="site-main">
        <div id="swup">{children}</div>
      </main>
      <footer className="site-foot">
        &copy; {new Date().getFullYear()} {title}
      </footer>
    </div>
  )
}

export default Layout
